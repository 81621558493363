import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js?20240328";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import * as serviceWorker from "./serviceWorker";
// import ProtectedRoute from "./components/Utils/ProtectedRoute.jsx";
// import { Login } from "./components/Login/Login.jsx";
import { UserProvider } from "./providers/UserProvider";

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <Router>
        <Switch>
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
